<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title">
              Выберите дополнительные инструментальные исследования, которые Вам
              необходимы для постановки окончательного диагноза и определения
              тактики лечения пациентки?
            </div>
            <div class="d-none d-xl-block">
              <RadioButton
                class="mt-6 mb-2 radiobtn_simple"
                :inputValue="0"
                v-model="radioTabs[0]"
                :label="'Электрокардиография (ЭКГ)'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="1"
                v-model="radioTabs[0]"
                :label="'Чреспищеводная эхокардиография (ЧПЭхоКГ)'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="2"
                v-model="radioTabs[0]"
                :label="'МРТ сердца'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="3"
                v-model="radioTabs[0]"
                :label="'КТ-пульмонография'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="4"
                v-model="radioTabs[0]"
                :label="'КТ-коронарография'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="5"
                v-model="radioTabs[0]"
                :label="'Коронароангиография'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="6"
                v-model="radioTabs[0]"
                :label="'УЗИ органов брюшной полости и почек'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="7"
                v-model="radioTabs[0]"
                :label="'Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне'"
              />
            </div>
            <div class="d-xl-none">
              <Select
                :placeholder="'Выберите результат'"
                :items="list"
                :multiple="false"
                v-model="radioTabs"
                :searchable="false"
              >
                <template #list-header>
                  <div class="select-form__select-header">
                    Выберите результат
                  </div>
                </template>
              </Select>
            </div>
          </div>
          <div
            class="icc__block-white-plate"
            :style="`opacity: ${radioTabs[0] == null ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 0"
            >
              <div class="title-1 mb-4">Электрокардиография (ЭКГ)</div>
              <div class="text-2 mb-4">
                Синусовый ритм с ЧСС 66/мин, одна наджелудочковая экстрасистола,
                ЭОС смещена влево
              </div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step9__table1.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step9__table1.jpg')} 3x`"
                />
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 1"
            >
              <div class="title-1 mb-4">
                Чреспищеводная эхокардиография (ЧПЭхоКГ)
              </div>
              <div class="text-2 mb-5">
                <div class="mb-2">
                  Показание к проведению исследования: внутрисердечные тромбы,
                  митральная недостаточность
                </div>
                <div class="mb-2">Митральная недостаточность ст.0</div>
                <div class="mb-2">
                  Тромбов в ушке левого предсердия и полостях сердца не найдено.
                </div>
                <div class="mb-2">Спонтанное контрастирование 2 ст.</div>
                <div class="mb-2">
                  Механическая функция ушка левого предсердия снижена.
                </div>
                <div class="mb-2">
                  Скорость кровотока в ушке снижена (45 см/сек).
                </div>
                <div class="mb-2">Исследование прошло без осложнений.</div>
                <div>
                  <b>Заключение:</b> внутрисердечных тромбов не найдено.
                </div>
              </div>
              <div class="d-flex mb-4">
                <img
                  data-coolbox="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/step9/tab2/1.mp4"
                  class="mr-4"
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step9__rentgen1.jpg')} 2x`"
                />
                <img
                  data-coolbox="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/step9/tab2/2.mp4"
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step9__rentgen2.jpg')} 2x`"
                />
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 2"
            >
              <div class="title-1 mb-4">МРТ сердца</div>
              <div class="text-2 mb-4">
                <div>
                  <b>Заключение:</b> ФВ 58%, ГЛЖ, зон нарушения локальной
                  сократимости не выявлено, клапанный аппарат без значимых
                  изменений, зон отсроченного накопления в миокарде не выявлено,
                  отека нет.
                </div>
              </div>
              <div class="d-flex mb-4">
                <div class="full-screen mr-4">
                  <div
                    class="full-screen__trigger"
                    :data-coolbox="
                      require('@/assets/img/interactive-clinical-case/step9__rentgen3_full.jpg')
                    "
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="40" height="40" rx="8" fill="#830051"></rect>
                      <path
                        d="M23 11H29V17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17 29H11V23"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M29 11L22 18"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M11 29L18 22"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>

                  <img
                    :srcset="`${require('@/assets/img/interactive-clinical-case/step9__rentgen3.jpg')} 2x`"
                  />
                </div>
                <div class="full-screen">
                  <div
                    class="full-screen__trigger"
                    :data-coolbox="
                      require('@/assets/img/interactive-clinical-case/step9__rentgen4_full.jpg')
                    "
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="40" height="40" rx="8" fill="#830051"></rect>
                      <path
                        d="M23 11H29V17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17 29H11V23"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M29 11L22 18"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M11 29L18 22"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>

                  <img
                    :srcset="`${require('@/assets/img/interactive-clinical-case/step9__rentgen4.jpg')} 2x`"
                  />
                </div>
              </div>
              <div class="full-screen">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step9__rentgen5_full.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step9__rentgen5.jpg')} 2x`"
                />
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 3"
            >
              <div class="title-1 mb-4">КТ-пульмонография</div>
              <div class="text-2 mb-4">
                <div>
                  <b>Заключение:</b> имеется удовлетворительное контрастное
                  заполнение легочного дерева. Нет дефекта наполнения в главном
                  легочном стволе, легочных артериях, основных сегментарных
                  ветвях, указывающих на ТЭЛА. Нарушений перфузии нет. Сердце в
                  норме. Выпота в перикарде нет. Лимфаденопатии нет.
                  Новообразований в легких или узелков нет. Плеврального выпота
                  нет.
                </div>
              </div>
              <div class="d-flex mb-4">
                <div class="full-screen mr-4">
                  <div
                    class="full-screen__trigger"
                    :data-coolbox="
                      require('@/assets/img/interactive-clinical-case/step9__rentgen6_full.jpg')
                    "
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="40" height="40" rx="8" fill="#830051"></rect>
                      <path
                        d="M23 11H29V17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17 29H11V23"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M29 11L22 18"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M11 29L18 22"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>

                  <img
                    :srcset="`${require('@/assets/img/interactive-clinical-case/step9__rentgen6.jpg')} 2x`"
                  />
                </div>
                <div class="full-screen">
                  <div
                    class="full-screen__trigger"
                    :data-coolbox="
                      require('@/assets/img/interactive-clinical-case/step9__rentgen7_full.jpg')
                    "
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="40" height="40" rx="8" fill="#830051"></rect>
                      <path
                        d="M23 11H29V17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17 29H11V23"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M29 11L22 18"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M11 29L18 22"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>

                  <img
                    :srcset="`${require('@/assets/img/interactive-clinical-case/step9__rentgen7.jpg')} 2x`"
                  />
                </div>
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 4"
            >
              <div class="title-1 mb-4">КТ-коронарография</div>
              <div class="text-2 mb-4">
                <div>
                  <b>Заключение:</b> тип кровоснабжения левый. Кальциевый индекс
                  295 (CAD-DRS 2). КТ данные стеноза ПМЖА до 50% (CAD-RADS 2 –
                  умеренное незначимое стенозирование).
                </div>
              </div>
              <div class="d-flex mb-4">
                <div class="full-screen mr-4">
                  <div
                    class="full-screen__trigger"
                    :data-coolbox="
                      require('@/assets/img/interactive-clinical-case/step9__rentgen8_full.jpg')
                    "
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="40" height="40" rx="8" fill="#830051"></rect>
                      <path
                        d="M23 11H29V17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17 29H11V23"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M29 11L22 18"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M11 29L18 22"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>

                  <img
                    :srcset="`${require('@/assets/img/interactive-clinical-case/step9__rentgen8.jpg')} 2x`"
                  />
                </div>
                <div class="full-screen">
                  <div
                    class="full-screen__trigger"
                    :data-coolbox="
                      require('@/assets/img/interactive-clinical-case/step9__rentgen9_full.jpg')
                    "
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="40" height="40" rx="8" fill="#830051"></rect>
                      <path
                        d="M23 11H29V17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17 29H11V23"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M29 11L22 18"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M11 29L18 22"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>

                  <img
                    :srcset="`${require('@/assets/img/interactive-clinical-case/step9__rentgen9.jpg')} 2x`"
                  />
                </div>
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 5"
            >
              <div class="title-1 mb-4">Коронароангиография</div>
              <div class="text-2 mb-4">
                <div>
                  <b>Заключение:</b> тип кровоснабжения левый. В ПМЖА стеноз
                  40%.
                </div>
              </div>
              <div class="d-flex mb-4">
                <div class="full-screen mr-4">
                  <div
                    class="full-screen__trigger"
                    :data-coolbox="
                      require('@/assets/img/interactive-clinical-case/step9__rentgen10_full.jpg')
                    "
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="40" height="40" rx="8" fill="#830051"></rect>
                      <path
                        d="M23 11H29V17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17 29H11V23"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M29 11L22 18"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M11 29L18 22"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>

                  <img
                    :srcset="`${require('@/assets/img/interactive-clinical-case/step9__rentgen10.jpg')} 2x`"
                  />
                </div>
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 6"
            >
              <div class="title-1 mb-4">
                УЗИ органов брюшной полости и почек
              </div>
              <div class="text-2 mb-4">
                <div class="mb-2">
                  <b>Правая почка</b> 104 х 50 мм, положение и форма типичные,
                  контуры ровные, паренхима толщиной 16 - 17 мм, однородная,
                  равномерно гипоэхогенная, кортико-медуллярная дифференцировка
                  не выражена, ЧЛС не расширена.
                </div>
                <div class="mb-2">
                  <b>Левая почка</b> 105 х 51 мм, положение и форма типичные,
                  контуры ровные, паренхима толщиной 16 - 17 мм, однородная,
                  равномерно гипоэхогенная, кортико-медуллярная дифференцировка
                  не выражена, ЧЛС не расширена.
                </div>
                <div class="mb-2">
                  При сканировании стоя положение почек не меняется.
                </div>
                <div class="mb-2">
                  <b>Печень</b> – границы печени не расширены: нижний край
                  правой доли у рёберной дуги, незначительно закруглён,
                  переднезадний размер правой доли 116 мм, косой вертикальный
                  145 мм, переднезадний размер левой доли 57 мм, вертикальный 89
                  мм; контуры ровные, диафрагмальный контур нечёткий,
                  эхоструктура однородная, диффузно повышенной эхогенности,
                  рисунок зернистости нечёткий; внутрипечёночные жёлчные протоки
                  не расширены, свободны, сосудистый рисунок обеднен, отмечается
                  затухание акустических сигналов в задних отделах.
                </div>
                <div class="mb-2">
                  Общий жёлчный проток диаметром 4 мм, стенки не утолщены,
                  просвет свободный.
                </div>
                <div class="mb-2">
                  <b>Жёлчный пузырь</b> размерами 83 х 27 х 24 мм, контуры
                  чёткие; стенка на всём протяжении не контурирована,
                  однородная, толщиной 2 - 3 мм, просвет свободный. 
                </div>
                <div class="mb-2">
                  <b>Поджелудочная железа</b> типичной формы и расположения,
                  имеет нечёткие, ровные контуры, толщина головки 27 мм, тела -
                  17 мм, хвоста – 28 мм, эхоструктура однородная, диффузно
                  повышенной эхогенности, рисунок зернистости не выражен,
                  главный панкреатический проток не расширен, просвет его
                  свободен.
                </div>
                <div class="mb-2">
                  Регионарные лимфатические узлы не визуализируются.
                </div>
                <div>
                  <b>Заключение:</b> Эхографические признаки диффузных изменений
                  печени по типу стеатоза. Эхоструктурные изменения почек
                  возрастного характера.
                </div>
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 7"
            >
              <div class="title-1 mb-4">
                Дуплексное сканирование брахиоцефальных сосудов на
                экстракраниальном уровне
              </div>
              <div class="text-2 mb-4">
                <div class="mb-2">
                  КИМ диффузно равномерно утолщен (справа и слева: 1,0 – 1,1 –
                  1,1 мм). Дифференцировка на слои нарушена.
                </div>
                <div class="mb-2">
                  Общие сонные артерии: проходимы справа и слева. В бифуркации
                  левой ОСА – полуциркулярная гиперэхогенная, с ровной
                  поверхностью АСБ, стенозирующая просвет сосуда до 30% (по
                  диаметру, ESCT). Нестабильные АСБ не выявлены. Аномалии хода,
                  патологические извитости не обнаружены. Кровоток достаточный,
                  симметричный. Тип кровотока магистральный.
                </div>
                <div class="mb-2">
                  Наружные сонные артерии: проходимы справа и слева.
                  Нестабильные АСБ не выявлены.
                </div>
                <div class="mb-2">
                  Аномалии хода, патологические извитости не обнаружены.
                  Кровоток достаточный, симметричный. Тип кровотока
                  магистральный.
                </div>
                <div class="mb-2">
                  Внутренние сонные артерии: проходимы справа и слева.
                  Нестабильные АСБ не выявлены.
                </div>
                <div class="mb-2">
                  Аномалии хода, патологические извитости не обнаружены.
                  Кровоток достаточный, симметричный. Тип кровотока
                  магистральный.
                </div>
                <div class="mb-2">
                  Подключичные артерии проходимы справа и слева, кровоток
                  магистральный, достаточный, симметричный.
                </div>
                <div class="mb-2">
                  <b>Заключение:</b> Атеросклероз БЦА: стеноз левой ОСА 30%.
                </div>
              </div>
              <div class="d-flex mb-4">
                <div class="full-screen mr-4">
                  <div
                    class="full-screen__trigger"
                    :data-coolbox="
                      require('@/assets/img/interactive-clinical-case/step9__rentgen11_full.jpg')
                    "
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="40" height="40" rx="8" fill="#830051"></rect>
                      <path
                        d="M23 11H29V17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17 29H11V23"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M29 11L22 18"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M11 29L18 22"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>

                  <img
                    :srcset="`${require('@/assets/img/interactive-clinical-case/step9__rentgen11.jpg')} 2x`"
                  />
                </div>
                <div class="full-screen">
                  <div
                    class="full-screen__trigger"
                    :data-coolbox="
                      require('@/assets/img/interactive-clinical-case/step9__rentgen12_full.jpg')
                    "
                  >
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="40" height="40" rx="8" fill="#830051"></rect>
                      <path
                        d="M23 11H29V17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17 29H11V23"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M29 11L22 18"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M11 29L18 22"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>

                  <img
                    :srcset="`${require('@/assets/img/interactive-clinical-case/step9__rentgen12.jpg')} 2x`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :stepsCount="18"
        :activeStep="8"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import RadioButton from "@/components/interactiveClinicalCase/RadioButton.vue";
import Select from "@/components/interactiveClinicalCase/Select.vue";
export default {
  name: "ICCStep9",
  components: {
    Steps,
    RadioButton,
    Select,
  },
  data: () => ({
    radioTabs: [],
  }),
  computed: {
    list() {
      return [
        { label: "Электрокардиография (ЭКГ)", value: 0 },
        { label: "Чреспищеводная эхокардиография (ЧПЭхоКГ)", value: 1 },
        { label: "МРТ сердца", value: 2 },
        { label: "КТ-пульмонография", value: 3 },
        { label: "КТ-коронарография", value: 4 },
        { label: "Коронароангиография", value: 5 },
        { label: "УЗИ органов брюшной полости и почек", value: 6 },
        {
          label:
            "Дуплексное сканирование брахиоцефальных сосудов на экстракраниальном уровне",
          value: 7,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";

.icc {
  & .select-form {
    &__select-header {
      margin-bottom: 16px;
      padding: 10px 16px;
      border-radius: 8px;
      background: #f8f8f8;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #3c4242;
    }
  }
}
</style>